<template>
  <div class="correct_wrap">
    <!-- 按钮区域 -->
    <div class="btn_wrap">
      <el-button type="primary"
                 @click="exportCard">导出面批答题卡</el-button>
      <el-button type="primary"
                 @click="uploadCard"
                 v-if="isSign">上传学生答题卡</el-button>
    </div>

    <!-- 答题卡列表 -->
    <div>
      <div class="title">
        <span> 已上传答题卡列表</span>
      </div>
      <Table ref="TablesRef"
             :tableData="tableData" />
      <pagination ref="pagination"
                  :get-data="getCardList"
                  :isInit="false"
                  :now-page.sync="page"
                  :now-size.sync="size"
                  :total="totalElements" />
    </div>

    <div v-show="showProgresss"
         class="progresss_wrap">
      <el-progress type="circle"
                   :percentage="percentage"
                   :color="customColors"
                   :status="percentage==100?'success':null"></el-progress>
    </div>

  </div>

</template>

<script>
import getMulFile from '@/utils/mulFile.js'
import { getCardPdf, uploadCard, getUploadCard, uploadSheetSchedule } from '@/api/correctPaperCard.js'
import Vue from 'vue'
export default {
  data () {
    return {

      edu_paper_id: '',
      tableColumns: [
        { prop: 'name', align: 'center', label: '图片名' },
        { prop: 'create_time', align: 'center', label: '上传时间', },
        { prop: 'status', align: 'center', label: '审核状态', isStatus: true },
        {
          prop: 'operation', align: 'center', label: '操作', width: '200', type: 'text', btns: [
            { name: '', class: 'text_btn_primary', event: 'toInfo', isChangeText: true },
          ]
        },
      ],
      tableData: [],
      page: 1,
      size: 10,
      totalElements: 0,
      showProgresss: false,
      percentage: 0,
      isUploading: false,// 是否正在上传文件
      isSign: 0,// 是否已导出过答题卡,未导出过则无标注数据无法上传
      sitv: undefined,//是否加载中定时器
      customColors: [
        { color: '#f56c6c', percentage: 20 },
        { color: '#e6a23c', percentage: 50 },
        { color: '#1989fa', percentage: 75 },
        { color: '#5cb87a', percentage: 100 },
      ]
    }

  },
  mounted () {
    this.edu_paper_id = this.$route.query.edu_paper_id
    this.setTablesColums()
    // this.getCardList()
  },
  activated () {
    this.getCardList()
  },
  methods: {
    getClass (prop, value) {
      return value ? 'pass' : 'notpass'
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    getValue (prop, value) {
      if (prop == 'status') {
        return value ? '已审核' : '未审核'
      }
    },
    getText (prop, value, row) {
      if (prop == 'operation') {
        // return row.status ? '详情' : '识别'
        return '识别'
      }
    },
    /** 导出答题卡 */
    async exportCard () {
      const { data } = await getCardPdf({
        edu_paper_id: this.edu_paper_id
      })
      if (data) {
        let url = data.PDFUrl + '?response-content-type=application/octet-stream'
        window.open(url)
      }
      this.isSign = 1
    },
    /** 已上传答题卡列表 */
    async getCardList (page, limit) {
      let params = {
        page,
        limit,
        edu_paper_id: this.edu_paper_id
      }
      const { data } = await getUploadCard(params)
      this.isSign = data.has_mark
      this.tableData = data.list
      this.totalElements = data.count
      console.log(data)

    },

    /** 上传学生答题卡，打开选择文件 */
    async uploadCard () {
      const res = await getMulFile({ limit: 20, fileType: "image/*" })
      console.log('提交', res)
      setTimeout(() => {
        if (this.isUploading) {
          this.showProgresss = true
        }
      }, 1200);
      this.isUploading = true
      this.SubmitFiles(res)
    },

    /** 上传学生答题卡，选择文件后提交 */
    async SubmitFiles (res) {
      // 当count1,count2相等则已经返回进度，才可以发起下一次请求
      let count1 = 0, count2 = 0
      let randomProgress = Math.random() + ''
      this.sitv = setInterval(async () => {
        if (count1 == count2) {
          count1 += 1
          let { data } = await uploadSheetSchedule({
            "progress": randomProgress,
            "test_paper_id": this.edu_paper_id
          })
          count2 += 1
          this.percentage = Math.round(data.schedule)
        }
      }, 1000);
      try {
        let formData = new FormData()
        let arr = []
        for (let i = 0; i < res.length; i++) {
          let name = res[i].name.replace(/\s*/g, "") //去除文件名的空格
          arr.push(name)
          formData.append(arr[i], res[i])
        }
        let form = {
          'edu_paper_id': this.edu_paper_id,
          'progress': randomProgress
        }
        for (let i in form) {
          formData.append(i, form[i])
        }
        formData.append('filenames', arr)
        formData.append('sign', Vue.prototype.$getMd5Form({ ...form, 'filenames': arr.join(',') }))

        const { data } = await uploadCard(formData)
        this.percentage = 100
        clearInterval(this.sitv)
        this.isUploading = false
        this.showProgresss = false
        this.getCardList()
        this.percentage = 0
      }
      catch (err) {
        clearInterval(this.sitv)
        this.showProgresss = false
        this.percentage = 0
        this.isUploading = false

      }
      // for (var value of formData.values()) {
      //   console.log(value);
      // }
    },

    toInfo (row) {
      this.$router.push(
        {
          path: '/correctPaperCard/scanCard',
          query: {
            url: row.url,
            paper_no_nstandard_sheet_id: row.paper_no_nstandard_sheet_id
          }
        })
    }

  }

}
</script>

<style lang="scss" scoped>
.correct_wrap {
  .btn_wrap {
    margin-bottom: 20px;
  }
  .el-button {
    width: auto;
    padding: 0 15px;
  }
  .progresss_wrap {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.6);
    z-index: 3;
    // display: flex;
    .el-progress--circle,
    .el-progress--dashboard {
      width: 100%;
      height: 90%;
      display: flex;
      align-items: center;
    }
  }
  .title {
    margin-bottom: 10px;
    // border-left: 4px solid #1e7bd2;
    font-size: 16px;
    font-weight: 700;
    position: relative;
    span {
      position: relative;
      padding: 10px 20px;
      display: inline-block;
      background: #fff;
      z-index: 1;
      &::before {
        content: "";
        width: 4px;
        // height: 14px;
        height: 18px;
        border-radius: 3px;
        position: absolute;
        top: 50%;
        left: 0;
        margin-top: -9px;
        background: #1e7bd2;
      }
    }

    &::after {
      content: "";
      width: 100%;
      height: 1px;
      border-radius: 3px;
      position: absolute;
      top: 50%;
      left: 0;
      background: #dddddf;
    }
  }
}
::v-deep .el-progress-circle {
  margin: 0 auto;
  display: block;
}
</style>